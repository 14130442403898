import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '_components/_core/Modal';

import styles from './styles.module.scss';

export default function ConfirmEmailModal({ isVisible, email }) {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);

  const handleToggleModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      isVisible={isModalVisible}
      title=""
      className={styles.body}
      headerClassName={styles.header}
      footerClassName={styles.foter}
      dialogClassName={styles.container}
      toggleModal={handleToggleModal}
    >
      <img
        src="assets/icones/png/email.png"
        className="img-lg mb-2"
        alt="E-mail de confirmação enviado"
      />
      <h4>
        Confirmação de e-mail
      </h4>
      <p className={styles.bodyText}>
        Enviamos um e-mail para
        {' '}
        <button type="button" className="btn-link">{email}</button>
        {' '}
        para confirmar que é você mesmo(a).
        Clique no link enviado no e-mail para ativar sua conta.
      </p>
      <p className={styles.footerText}>
        Não recebeu nenhum e-mail?
        {' '}
        <button type="button" className="btn-link">Enviar novamente</button>
        .
      </p>
    </Modal>
  );
}

ConfirmEmailModal.propTypes = {
  isVisible: PropTypes.any,
  email: PropTypes.any,
};
