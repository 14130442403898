import React from 'react';
import { Link } from 'react-router-dom';

import { SvgContainer } from './styles';

export default function LogoSmall() {
  return (
    <Link className="navbar-brand" to="/dashboard">
      <SvgContainer>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 35 35" fill="none">
          <rect width="35" height="35" rx="4" fill="#464DE4" />
          <path d="M17.8654 27C21.0846 27 24.1308 25.3859 26 22.6491L21.7077 19.6667C20.8423 20.9649 19.3885 21.7368 17.8654 21.7368C15.2692 21.7368 13.1923 19.5965 13.1923 17C13.1923 14.3684 15.2692 12.2631 17.8654 12.2631C19.3538 12.2631 20.8077 13 21.673 14.2631L25.9308 11.2456C25.0307 9.94739 23.8192 8.89472 22.4692 8.15787C21.05 7.38593 19.4577 7 17.8654 7C16.5154 7 15.2346 7.24564 14.0231 7.77194C12.8461 8.29824 11.7731 8.99999 10.873 9.9123C9.97307 10.8596 9.24614 11.9123 8.76147 13.1053C8.24226 14.3333 8 15.6666 8 17C8 18.3333 8.24226 19.6667 8.76147 20.8947C9.24614 22.0877 9.97307 23.1403 10.873 24.0877C11.7731 25 12.8461 25.7018 14.0231 26.2281C15.2346 26.7193 16.5154 27 17.8654 27Z" fill="white" />
        </svg>
      </SvgContainer>
    </Link>
  );
}
