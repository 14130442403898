import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo() {
  const publicFolder = process.env.PUBLIC_URL;

  return (
    <Link className="navbar-brand" to="/dashboard">
      <img
        src={`${publicFolder}/assets/img/contabills-logo.svg`}
        alt="logo"
        width="145"
        className="d-inline-block align-top ml-1 mr-1"
      />
    </Link>
  );
}
