import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import {
  FaArrowLeft,
  FaBriefcase,
  FaFileDownload,
  FaPiggyBank,
  FaSitemap,
  FaTags,
  FaTrash,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import {
  IoBookmark,
  IoOptions,
  IoReader,
} from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'helpers';

import { Button, PageHeader } from '_components/_core';

import {
  EmployeeRoles,
  EmployeeJourneys,
  Accountants,
  Subscription,
  Profile,
  Users,
  DeleteFinancialData,
  Accounts,
  Categories,
  CostsCenter,
  Tags,
  Companies,
  ExportFinancialData,
  Preferences,
} from './components';
import { Menu, MenuCategory } from './styles';

function Settings({ user }) {
  const location = useLocation();
  const { isMobile } = useMediaQuery();

  const contabills_tabs = useMemo(() => ({
    users: () => (<Users />),
    accountants: () => (<Accountants />),
    profile: () => (<Profile />),
    employee_role: () => (<EmployeeRoles />),
    employee_journey: () => (<EmployeeJourneys />),
    subscription: () => (<Subscription />),
  }), []);

  const zenpaper_tabs = useMemo(() => ({
    profile: () => (<Profile />),
    subscription: () => (<Subscription />),
    users: () => (<Users />),
    companies: () => (<Companies />),
    accounts: () => <Accounts />,
    categories: () => (<Categories />),
    costs_center: () => (<CostsCenter />),
    tags: () => <Tags />,
    financial_data: () => (<DeleteFinancialData />),
    export_data: () => (<ExportFinancialData />),
    preferences: () => (<Preferences />),
  }), []);

  const [activeTab, setActiveTab] = useState(() => {
    const tabs = process.env.REACT_APP_CONTABILLS_APP === 'contabills' ? contabills_tabs : zenpaper_tabs;

    const urlParams = new URLSearchParams(location.search);

    const tab = urlParams.get('tab');

    if (tab && Object.keys(tabs).includes(tab)) {
      return tab;
    }

    if (isMobile) {
      return null;
    }

    return 'profile';
  });

  const renderActiveTab = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    if (!activeTab) {
      return null;
    }

    switch (product) {
      case 'contabills': {
        const ActiveTab = contabills_tabs[activeTab];

        return <ActiveTab />;
      }
      case 'contabills-empresas': {
        const ActiveTab = zenpaper_tabs[activeTab];

        return <ActiveTab />;
      }
      default: {
        const ActiveTab = contabills_tabs[activeTab];

        return <ActiveTab />;
      }
    }
  }, [contabills_tabs, zenpaper_tabs, activeTab]);

  const ContabillsMenu = () => (
    <Menu>
      <MenuCategory>
        Geral
      </MenuCategory>
      <ListGroup.Item as="button" action eventKey="subscription" onClick={() => setActiveTab('subscription')}>
        Meu Plano
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="profile" onClick={() => setActiveTab('profile')}>
        Perfil
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="preferences" onClick={() => setActiveTab('preferences')}>
        Preferências
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="users" onClick={() => setActiveTab('users')}>
        Usuários
      </ListGroup.Item>
      <MenuCategory>
        Parâmetros
      </MenuCategory>
      <ListGroup.Item as="button" action eventKey="accountants" onClick={() => setActiveTab('accountants')}>
        Contadores
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="employee_role" onClick={() => setActiveTab('employee_role')}>
        Cargos
      </ListGroup.Item>
      <ListGroup.Item as="button" action eventKey="employee_journey" onClick={() => setActiveTab('employee_journey')}>
        Jornada Semanal
      </ListGroup.Item>
    </Menu>
  );

  const ContabillsEmpresasMenu = () => {
    const isManager = user.manager_id === null;

    return (
      <Menu>
        <MenuCategory>
          Geral
        </MenuCategory>
        <ListGroup.Item
          as="button"
          action
          eventKey="profile"
          onClick={() => setActiveTab('profile')}
        >
          <FaUser className="mr-3" /> Meus dados
        </ListGroup.Item>
        <ListGroup.Item
          as="button"
          action
          eventKey="preferences"
          onClick={() => setActiveTab('preferences')}
        >
          <IoOptions className="mr-3" /> Preferências
        </ListGroup.Item>
        <ListGroup.Item
          as="button"
          action
          eventKey="subscription"
          onClick={() => setActiveTab('subscription')}
        >
          <IoReader className="mr-3" /> Meu plano
        </ListGroup.Item>
        <ListGroup.Item
          as="button"
          action
          eventKey="users"
          onClick={() => setActiveTab('users')}
        >
          <FaUsers className="mr-3" /> Usuários
        </ListGroup.Item>
        <ListGroup.Item
          as="button"
          action
          eventKey="companies"
          onClick={() => setActiveTab('companies')}
        >
          <FaBriefcase className="mr-3" /> Empresas
        </ListGroup.Item>
        <MenuCategory>
          Parâmetros (Empresa Ativa)
        </MenuCategory>
        <ListGroup.Item
          className="d-flex align-items-center"
          as="button"
          action
          eventKey="accounts"
          onClick={() => setActiveTab('accounts')}
        >
          <FaPiggyBank className="mr-3" /> Contas bancárias
        </ListGroup.Item>
        <ListGroup.Item
          className="d-flex align-items-center"
          as="button"
          action
          eventKey="categories"
          onClick={() => setActiveTab('categories')}
        >
          <IoBookmark className="mr-3" /> Categorias
        </ListGroup.Item>
        <ListGroup.Item
          className="d-flex align-items-center"
          as="button"
          action
          eventKey="costs_center"
          onClick={() => setActiveTab('costs_center')}
        >
          <FaSitemap className="mr-3" /> Centros de custo
        </ListGroup.Item>
        <ListGroup.Item
          className="d-flex align-items-center"
          as="button"
          action
          eventKey="tags"
          onClick={() => setActiveTab('tags')}
        >
          <FaTags className="mr-3" /> Marcadores (Tags)
        </ListGroup.Item>
        <ListGroup.Item
          className="d-flex align-items-center"
          as="button"
          action
          eventKey="export_data"
          onClick={() => setActiveTab('export_data')}
        >
          <FaFileDownload className="mr-3" /> Exportar dados
        </ListGroup.Item>
        {isManager && (
          <ListGroup.Item
            as="button"
            className="d-flex align-items-center text-danger"
            action
            eventKey="financial_data"
            onClick={() => setActiveTab('financial_data')}
          >
            <FaTrash className="mr-3" />
            Excluir dados
          </ListGroup.Item>
        )}
      </Menu>
    );
  };

  const renderMenu = useCallback(() => {
    const product = process.env.REACT_APP_CONTABILLS_APP;

    switch (product) {
      case 'contabills':
        return <ContabillsMenu />;
      case 'contabills-empresas':
        return <ContabillsEmpresasMenu />;
      default:
        return <ContabillsMenu />;
    }
  }, []);

  if (isMobile) {
    return (
      <Container fluid className="content-wrapper">
        <Tab.Container defaultActiveKey={activeTab}>
          <Row>
            {!activeTab && (
              <Col sm={12} xl={3}>
                {renderMenu()}
              </Col>
            )}
            {activeTab && (
              <Col sm={12} xl={9}>
                <Tab.Content>
                  <Button variant="link" className="p-0 m-0 pb-3" onClick={() => setActiveTab(null)}>
                    <FaArrowLeft className="mr-2" />
                    Voltar
                  </Button>
                  <Tab.Pane eventKey={activeTab}>
                    {renderActiveTab(activeTab)}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            )}
          </Row>
        </Tab.Container>
      </Container>
    );
  }

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Configurações"
        description="Gerencie as configurações"
        className="ml-3"
      />
      <Tab.Container defaultActiveKey={activeTab}>
        <Row>
          <Col sm={12} xl={3}>
            {renderMenu()}
          </Col>
          <Col sm={12} xl={9} className="mt-3 mt-xl-0">
            <Tab.Content>
              <Tab.Pane eventKey={activeTab}>
                {renderActiveTab(activeTab)}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

Settings.propTypes = {
  user: PropTypes.object,
};

export default Settings;
