import React from 'react';
import Container from 'react-bootstrap/Container';

import { PageHeader } from '_components/_core';
import { InvoiceUpload } from './components';

export default function Import() {
  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title="Importar Notas Fiscais"
        description="Faça importação de Notas Fiscais no formato XML."
      />
      <InvoiceUpload />
    </Container>
  );
}
