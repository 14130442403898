import React from 'react';

export default function Logo() {
  const publicFolder = process.env.PUBLIC_URL;

  return (
    <img
      src={`${publicFolder}/assets/img/contabills-logo.svg`}
      alt="logo"
      width="280"
      className="d-inline-flex mb-5"
    />
  );
}
